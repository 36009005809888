;(function () {
    'use strict';

    ready(function() {

        var heroScroller = document.querySelector('.js-homehero-scroller');
        if (typeof heroScroller !== 'undefined' && heroScroller !== null) {
        	heroScroller.addEventListener('click', function (e) {
                e.preventDefault();
                var targetElement = document.querySelector('.strips');
                if (typeof targetElement !== 'undefined' && targetElement !== null) {
                    smoothScrollTo(targetElement);
                }
            });
        }
    });

}());
